import React from "react";

function RefreshArrow() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 20.0039C14.9314 20.0041 17.6284 18.4018 19.0299 15.8271"
        stroke="#164230"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0033 12.0004C20.0033 7.58028 16.4201 3.99707 12 3.99707"
        stroke="#164230"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0026 3.99707C9.0712 3.99683 6.37423 5.5992 4.97266 8.17381"
        stroke="#164230"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99609 12C3.99609 16.4201 7.57931 20.0033 11.9994 20.0033"
        stroke="#164230"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.97266 8.17383C6.72213 4.95881 10.4297 3.34031 13.9768 4.24319C17.5239 5.14607 20.0065 8.34024 20.0059 12.0004"
        stroke="#164230"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99609 12C3.99553 15.6602 6.47811 18.8544 10.0252 19.7572C13.5723 20.6601 17.2799 19.0416 19.0294 15.8266"
        stroke="#164230"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04819 8.17322H4.51172V4.63574"
        stroke="#164230"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.957 15.8271H19.4935V19.3646"
        stroke="#164230"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RefreshArrow;
