import React from "react";

function Email() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="4.5"
        width="18"
        height="15"
        rx="4"
        stroke="#F06E36"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 8.98389L11.2046 10.8171C11.7124 11.0385 12.2893 11.0394 12.7978 10.8195L17 9.00251"
        stroke="#F06E36"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Email;
