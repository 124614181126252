import React from "react";

function CloseIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 1.83398C11.6663 1.83398 14.6663 4.83398 14.6663 8.50065C14.6663 12.1673 11.6663 15.1673 7.99967 15.1673C4.33301 15.1673 1.33301 12.1673 1.33301 8.50065C1.33301 4.83398 4.33301 1.83398 7.99967 1.83398ZM11.1036 5.38915C10.8691 5.15501 10.4892 5.15529 10.2551 5.38977L8.18801 7.46002L8.14581 7.49448C8.04223 7.56272 7.90158 7.55119 7.81055 7.45997L5.7445 5.38977C5.51036 5.15529 5.13046 5.15501 4.89598 5.38915L4.84223 5.45055C4.66307 5.6856 4.68072 6.02273 4.89535 6.23767L6.96389 8.31001C7.0678 8.41411 7.0678 8.58268 6.96391 8.68678L4.89535 10.7594C4.66121 10.9939 4.66149 11.3738 4.89598 11.6079C5.13046 11.8421 5.51036 11.8418 5.7445 11.6073L7.81051 9.53626C7.91517 9.43181 8.08401 9.43198 8.18805 9.53622L10.2551 11.6073C10.4892 11.8418 10.8691 11.8421 11.1036 11.6079L11.1573 11.5465C11.3365 11.3115 11.3189 10.9743 11.1042 10.7594L9.03533 8.6868C8.93141 8.58269 8.93141 8.4141 9.03534 8.31L11.1042 6.23767C11.3384 6.00319 11.3381 5.62329 11.1036 5.38915Z"
        fill="white"
      />
    </svg>
  );
}

export default CloseIcon;
