export default function LogoHorizontal() {
  return (
    <svg
      id="Calque_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 326 71.91"
      fill="#F06E36"
    >
      <defs></defs>
      <g id="Calque_1-2">
        <g>
          <path
            className="cls-1"
            d="M220.63,21.23c-.36-8.62,2.13-17.04,14.16-17.55,11.41-.48,15.38,5.78,15.78,15.34l.39,32.11c.36,8.62-1.68,18.27-13.8,18.78-11.32,.48-15.74-7.02-16.15-16.58l-.39-32.11Zm9.26,32.24c.22,5.29,2.46,8.27,7.75,8.46,3.72,.14,4.77-3.69,4.54-8.98l-.45-33.3c-.26-6.06-1.6-8.37-5.67-8.2-3.98,.17-6.88,2.66-6.62,8.72l.45,33.3Z"
          />
          <path
            className="cls-1"
            d="M295.52,17.26c-.15-7.33,4.37-14.94,16.35-14.31,11.36,.6,13.95,7.54,13.55,15.13l.57,37.16c-.36,6.84-3.74,14.29-15.81,13.66-11.28-.59-14.49-6.89-14.1-14.48l-.57-37.16Zm9.36,38.02c-.07,5.35,2.93,7.08,6.55,7.27,3.7,.19,5.48-2.42,5.7-6.62l-.53-38.1c-.08-4.75-1.16-6.75-5.21-6.96-3.52-.18-6.04,1.75-6.82,5.03-.17,.7-.21,1.43-.2,2.15l.51,37.24Z"
          />
          <path
            className="cls-1"
            d="M289.08,53.36l-1.65-40.7c-.54-7.7-4.98-10.01-9.87-9.67-1.98,.14-3.72,.68-5.21,1.72-1.5,.94-2.66,2.28-3.49,3.9h-.21s.9-2.89-.3-4.36c-1.2-1.46-8.83-2.44-9.16,.64-.33,3.08-.23,48.83,1.68,50.11,1.92,1.28,8.02,.6,9.78-.68s-.82-37.73-.82-37.73c-.28-4.06,1.49-5.65,3.88-5.81,3.02-.21,3.88,1.72,4.07,4.42l1.54,37.11c.14,3.76,1.15,3.46,4.78,3.98,5.14,.73,5.11-.93,4.98-2.94Z"
          />
          <path
            className="cls-1"
            d="M260.36,69.65c-1.33-.03-2.05-5.13,0-6.51,3.17-2.13,27.81-3.1,30.08-.22,1.93,2.46,.68,6.11-.8,6.41-.98,.2-28.21,.35-29.29,.32Z"
          />
          <path
            className="cls-1"
            d="M112.71,64.52l.07-49.85c-.2-7.69-4.65-10.17-9.66-10.05-2.03,.05-3.83,.51-5.4,1.49-1.58,.87-2.82,2.15-3.74,3.73h-.21s1.04-2.85-.12-4.36c-1.16-1.51-8.91-2.81-9.39,.24-.47,3.05-2.06,61.48-.16,62.84,1.9,1.36,8.17,.95,10.03-.25,1.86-1.2,.49-50.48,.49-50.48-.11-4.05,1.77-5.55,4.23-5.62,3.09-.08,3.89,1.88,3.96,4.58l-.02,46.27c-.06,3.63,1.02,3.49,4.7,4.17,5.22,.95,5.26-.71,5.22-2.71Z"
          />
          <path
            className="cls-1"
            d="M47.45,23.28c-.3-9.43,4.96-15.15,14.61-15.46,8.32-.26,14.64,1.36,15.64,13.38,.58,6.92,1.75,13.57-2.57,16.76-3.39,2.5-11.77,2.21-15.2,1.98-.81-.06-1.49,.6-1.46,1.41l.33,13.2c.17,5.21,1.99,6.6,5.31,6.5,3.88-.12,4.9-2.93,4.76-7.37,0,0-.34-4.29,.99-5.62,1.33-1.33,5.92-2.71,7.63-1.72,1.71,.99,1.96,2.63,1.15,9.89-.81,7.26-3.55,11.12-15.09,11.49-7.88,.25-14.87-3.08-15.2-13.4l-.9-31.04Zm11.78,9.14c1.8,1.47,8.05,1.01,9.1-.65,1.04-1.65,.34-8.74,.34-8.74-.44-5.57-2.33-6.79-5.89-6.68-3.88,.12-4.53,3.2-4.91,6.26,0,0-.44,8.33,1.36,9.8Z"
          />
          <path
            className="cls-1"
            d="M.77,1.68C2.06-.02,16.53-.54,25.08,.63c7.56,1.04,13.54,2.82,14.2,17.43,.73,16.23-6.28,17.94-18.58,18.88,14.64-.45,23.52,4.87,24.11,15.18,.72,12.72-3.21,18.71-19.29,19.19,0,0-20.87,1.28-23.34-1.43C-.29,67.18-.51,3.38,.77,1.68ZM10.43,29.47c.15,1.14,11.87,2.02,16.15-1.96,2.17-2.01,3.9-8.51,1.44-14.45-3.06-7.36-16.75-4.9-17.37-3.1-1.38,4.04-.65,16.17-.22,19.51Zm1.42,32.12c2.24,1.85,7.76,1.64,15.12,.24,11.12-2.11,8.48-14.25-.83-16.15-11.88-2.42-15.53-.89-15.6,1.61-.06,2.03,.01,13.22,1.32,14.3Z"
          />
          <path
            className="cls-1"
            d="M171.96,1.98c1.29-1.7,15.75-2.22,24.31-1.05,7.56,1.04,13.54,2.82,14.2,17.43,.73,16.23-6.28,17.94-18.58,18.88,14.64-.45,23.52,4.87,24.11,15.18,.72,12.72-3.21,18.71-19.29,19.19,0,0-20.87,1.28-23.34-1.43-2.47-2.71-2.69-66.51-1.4-68.21Zm9.66,27.79c.15,1.14,11.87,2.02,16.15-1.96,2.17-2.01,3.9-8.51,1.44-14.45-3.06-7.36-16.75-4.9-17.37-3.1-1.38,4.04-.65,16.17-.22,19.51Zm1.42,32.12c2.24,1.85,7.76,1.64,15.12,.24,11.12-2.11,8.48-14.25-.83-16.15-11.88-2.42-15.53-.89-15.6,1.61-.06,2.03,.01,13.22,1.32,14.3Z"
          />
          <path
            className="cls-1"
            d="M117.54,23.86c-.3-9.43,4.96-15.15,14.61-15.46,8.32-.26,14.64,1.36,15.64,13.38,.58,6.92,1.75,13.57-2.57,16.76-3.39,2.5-11.77,2.21-15.2,1.98-.81-.06-1.49,.6-1.46,1.41l.33,13.2c.17,5.21,1.99,6.6,5.31,6.5,3.88-.12,4.9-2.93,4.76-7.37,0,0-.34-4.29,.99-5.62,1.33-1.33,5.92-2.71,7.63-1.72,1.71,.99,1.96,2.63,1.15,9.89-.81,7.26-3.55,11.12-15.09,11.49-7.88,.25-14.87-3.08-15.2-13.4l-.9-31.04Zm11.78,9.14c1.8,1.47,8.05,1.01,9.1-.65,1.04-1.65,.34-8.74,.34-8.74-.44-5.57-2.33-6.79-5.89-6.68-3.88,.12-4.53,3.2-4.91,6.26,0,0-.44,8.33,1.36,9.8Z"
          />
        </g>
      </g>
    </svg>
  );
}
