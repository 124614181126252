import React from "react";

function Comments() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6667 16.6668H15.8333C16.7542 16.6668 17.5 15.921 17.5 15.0002V5.00016C17.5 4.07933 16.7542 3.3335 15.8333 3.3335H4.16667C3.24583 3.3335 2.5 4.07933 2.5 5.00016V15.0002C2.5 15.921 3.24583 16.6668 4.16667 16.6668H8.33333L10 18.3335L11.6667 16.6668Z"
        stroke="#4C5450"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1482 10.0608C10.2298 10.1425 10.2298 10.2742 10.1482 10.3558C10.0665 10.4375 9.93482 10.4375 9.85315 10.3558C9.77148 10.2742 9.77148 10.1425 9.85315 10.0608C9.93482 9.98001 10.0665 9.98001 10.1482 10.0608"
        stroke="#4C5450"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4802 10.0608C13.5618 10.1425 13.5618 10.2742 13.4802 10.3558C13.3985 10.4375 13.2668 10.4375 13.1852 10.3558C13.1035 10.2742 13.1035 10.1425 13.1852 10.0608C13.2668 9.98001 13.3985 9.98001 13.4802 10.0608"
        stroke="#4C5450"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.81417 10.0608C6.89583 10.1425 6.89583 10.2742 6.81417 10.3558C6.7325 10.4375 6.60083 10.4375 6.51917 10.3558C6.4375 10.2742 6.4375 10.1425 6.51917 10.0608C6.60083 9.98001 6.7325 9.98001 6.81417 10.0608"
        stroke="#4C5450"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Comments;
