import React from "react";

function LogoFooter() {
  return (
    <svg
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_3)">
        <path
          d="M32.8482 67.0008C32.5772 60.6032 34.437 54.3594 43.382 53.985C51.8682 53.6273 54.8207 58.2737 55.1209 65.3661L55.4128 96.0483C55.6839 102.446 54.1618 109.605 45.146 109.983C36.7223 110.337 33.4362 104.775 33.136 97.683L32.844 67.0008H32.8482ZM39.7373 97.787C39.9041 101.714 41.568 103.923 45.5046 104.068C48.2694 104.168 49.0492 101.331 48.8824 97.4043L48.553 65.8361C48.3611 61.3394 47.3603 59.6256 44.3328 59.7546C41.372 59.8794 39.216 61.7263 39.4078 66.223L39.7373 97.7912V97.787Z"
          fill="#F06E36"
        />
        <path
          d="M84.5538 63.1198C84.5288 57.3211 88.4987 51.406 98.5029 51.9301C107.994 52.4251 109.329 57.5582 109.52 63.802L110 98.3902C109.7 104.093 106.877 110.295 96.8015 109.767C87.3853 109.276 84.6997 104.027 85.0292 97.708L84.5496 63.1198H84.5538ZM92.3728 98.4318C92.3144 102.891 94.8248 103.698 97.8399 103.856C100.93 104.018 102.415 102.467 102.598 98.9642L102.16 63.5857C102.06 59.3261 101.193 57.9617 97.8107 57.787C94.8332 57.6331 92.7106 59.2887 92.0892 62.0799C91.9683 62.6165 91.9391 63.1697 91.9475 63.7188L92.377 98.4318H92.3728Z"
          fill="#F06E36"
        />
        <path
          d="M80.955 97.3794L79.6247 57.4792C79.2452 52.0549 76.1051 50.4326 72.6522 50.6697C71.2552 50.7654 70.025 51.1439 68.97 51.8844C67.9066 52.5458 67.0892 53.4859 66.5012 54.6339L66.3553 54.6423C66.3553 54.6423 66.9892 52.5915 66.1384 51.5641C65.2919 50.5324 59.9041 49.8419 59.6706 52.0133C59.437 54.1805 59.8665 97.5582 61.2218 98.4609C62.5771 99.3636 66.8849 98.8852 68.1276 97.9825C69.3745 97.0799 67.1851 60.2579 67.1851 60.2579C66.985 57.4002 68.236 56.2812 69.9291 56.1647C72.06 56.0191 72.6731 57.3752 72.8023 59.2804L74.0575 96.6473C74.1576 99.297 74.8665 99.0849 77.4312 99.4509C81.0592 99.9667 81.0342 98.7937 80.9466 97.3794"
          fill="#F06E36"
        />
        <path
          d="M60.5088 109.7C59.5705 109.676 59.0617 106.086 60.5088 105.116C62.7481 103.615 80.146 102.933 81.7473 104.963C83.1109 106.693 82.2227 109.268 81.1801 109.476C80.4879 109.613 61.2636 109.721 60.5004 109.7"
          fill="#F06E36"
        />
        <path
          d="M0.533778 60.3369C1.42202 59.1389 11.4095 58.7646 17.3186 59.5965C22.5354 60.3286 26.6722 61.589 27.1226 71.9218C27.6272 83.4027 22.7857 84.6131 14.2911 85.2745C24.3995 84.9584 30.5338 88.7188 30.9341 96.0067C31.4304 105.004 28.7156 109.239 17.6147 109.58C17.6147 109.58 3.20267 110.487 1.50125 108.573C-0.200167 106.66 -0.354463 61.5391 0.533778 60.3369ZM7.20183 79.9875C7.30609 80.7945 15.3962 81.4185 18.3528 78.6023C19.8499 77.1797 21.0467 72.5832 19.3453 68.386C17.2352 63.178 7.77731 64.921 7.35196 66.198C6.397 69.0557 6.90158 77.6373 7.20183 79.9917M8.17765 102.708C9.72477 104.018 13.5321 103.864 18.6155 102.879C26.2927 101.385 24.4704 92.7995 18.04 91.4559C9.83736 89.7463 7.31443 90.8278 7.26439 92.5957C7.22269 94.0266 7.27273 101.947 8.17765 102.708Z"
          fill="#F06E36"
        />
        <path
          d="M82.794 39.1015L82.8399 8.96838C82.6939 3.41098 79.558 1.60981 76.0175 1.70133C74.5872 1.73877 73.3153 2.07155 72.2018 2.7787C71.0884 3.41098 70.2085 4.33444 69.558 5.47837H69.4078C69.4078 5.47837 70.146 3.4193 69.3244 2.32945C68.5029 1.2396 63.0317 0.295339 62.6981 2.5C62.3645 4.70466 61.2385 41.9176 62.5855 42.8993C63.9283 43.881 68.3486 43.5815 69.6622 42.7163C70.9758 41.8469 70.0083 11.2562 70.0083 11.2562C69.9333 8.32778 71.2594 7.23793 72.9942 7.19634C75.1793 7.1381 75.7423 8.55241 75.7923 10.5075L75.7798 38.0491C75.7381 40.6739 76.5012 40.5782 79.0992 41.0649C82.7857 41.7554 82.8107 40.5532 82.7857 39.1057"
          fill="#F06E36"
        />
        <path
          d="M34.4245 12.0341C34.2118 5.39102 37.9274 1.3644 44.7414 1.14809C50.6172 0.960901 55.0792 2.10483 55.784 10.574C56.1927 15.4493 57.0183 20.1331 53.9658 22.3752C51.568 24.1348 45.6589 23.9351 43.2319 23.7687C42.6605 23.7271 42.181 24.1889 42.2018 24.7587L42.4354 34.0557C42.5521 37.7288 43.8365 38.7063 46.1885 38.6314C48.9283 38.5441 49.6497 36.5641 49.5538 33.4401C49.5538 33.4401 49.3119 30.4201 50.2502 29.48C51.1885 28.5441 54.4287 27.5707 55.6339 28.2737C56.839 28.9767 57.0183 30.129 56.4429 35.2413C55.8674 40.3577 53.9366 43.0782 45.7882 43.3361C40.2252 43.5108 35.2877 41.1647 35.0584 33.8977L34.4245 12.0341V12.0341ZM42.7398 18.4734C44.0117 19.5133 48.4237 19.1847 49.1618 18.02C49.8999 16.8552 49.4037 11.8636 49.4037 11.8636C49.0951 7.93677 47.7565 7.07987 45.2502 7.1589C42.5104 7.24626 42.0517 9.41764 41.7848 11.5724C41.7848 11.5724 41.4721 17.4376 42.7439 18.4775"
          fill="#F06E36"
        />
        <path
          d="M0.62135 1.21465C1.53044 -0.0124721 11.7431 -0.391008 17.7857 0.457578C23.1193 1.20633 27.3478 2.49585 27.8107 13.0616C28.3236 24.8003 23.3778 26.0399 14.6914 26.718C25.0292 26.3977 31.3011 30.2371 31.7139 37.6955C32.2227 46.8968 29.4454 51.2271 18.0901 51.5765C18.0901 51.5765 3.35279 52.5042 1.60967 50.5449C-0.129276 48.5857 -0.287741 2.44177 0.62135 1.21465ZM7.4437 21.3103C7.54795 22.1339 15.8215 22.7745 18.849 19.8919C20.3795 18.4359 21.6055 13.7354 19.8624 9.4426C17.7023 4.11814 8.03169 5.89851 7.598 7.20467C6.62218 10.129 7.13511 18.9018 7.4437 21.3103M8.44454 44.5424C10.025 45.8819 13.9199 45.728 19.1159 44.7171C26.9683 43.1905 25.1043 34.4093 18.5279 33.0366C10.1376 31.2854 7.56047 32.3919 7.51042 34.2013C7.46872 35.6656 7.51876 43.7604 8.44454 44.5424"
          fill="#F06E36"
        />
        <path
          d="M87.598 12.0341C87.3853 5.39102 91.1009 1.3644 97.9149 1.14809C103.791 0.960901 108.253 2.10483 108.957 10.574C109.362 15.4493 110.192 20.1331 107.139 22.3752C104.741 24.1348 98.8324 23.9351 96.4053 23.7687C95.834 23.7271 95.3545 24.1889 95.3753 24.7587L95.6089 34.0557C95.7256 37.7288 97.01 38.7063 99.362 38.6314C102.102 38.5441 102.823 36.5641 102.727 33.4401C102.727 33.4401 102.485 30.4201 103.424 29.48C104.362 28.5441 107.602 27.5707 108.807 28.2737C110.013 28.9725 110.192 30.129 109.616 35.2413C109.041 40.3577 107.11 43.0782 98.9616 43.3361C93.3987 43.5108 88.4654 41.1647 88.2319 33.8977L87.598 12.0341V12.0341ZM95.9133 18.4734C97.1852 19.5133 101.597 19.1847 102.335 18.02C103.073 16.8552 102.577 11.8636 102.577 11.8636C102.269 7.93677 100.93 7.07987 98.4237 7.1589C95.6797 7.24626 95.2252 9.41764 94.9583 11.5724C94.9583 11.5724 94.6455 17.4376 95.9174 18.4775"
          fill="#F06E36"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_3">
          <rect width="110" height="110" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoFooter;
