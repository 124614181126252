import React from "react";

export default function Checkmark() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 14.75L9 12.5"
        stroke="#F06E36"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 11L11.25 14.75"
        stroke="#F06E36"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.5C7.02944 21.5 3 17.4706 3 12.5V12.5C3 7.52944 7.02944 3.5 12 3.5V3.5C16.9706 3.5 21 7.52944 21 12.5V12.5C21 17.4706 16.9706 21.5 12 21.5V21.5Z"
        stroke="#F06E36"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
