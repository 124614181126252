import React from "react";

function FooterHeadindHand() {
  return (
    <svg
      width="41"
      height="46"
      viewBox="0 0 41 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2687_1021)">
        <path
          d="M4.41534 31.6783C4.0199 29.0395 3.67009 26.3855 3.3659 23.7315C3.11495 21.5793 2.89441 19.4196 2.74232 17.2599C2.62064 15.5488 2.47616 13.7846 2.65867 12.0659C2.75753 11.0697 3.07692 10.0127 3.79176 9.27501C4.5066 8.53736 5.37353 8.89478 6.11878 9.38908C6.59027 9.70087 7.24427 9.5868 7.73857 9.38908C7.92869 9.31304 8.87167 8.7579 8.40778 8.45371C6.27088 7.03164 3.23662 7.28259 1.50276 9.26741C0.164341 10.7883 0.103504 12.7351 0.126318 14.6515C0.187155 19.3055 0.810737 23.9672 1.42671 28.5756C1.59402 29.8304 1.76892 31.0852 1.95143 32.3399C2.0655 33.108 4.54462 32.5452 4.41534 31.6783Z"
          fill="#F06E36"
        />
        <path
          d="M4.2331 30.3703C4.14184 27.9976 4.5601 25.5945 4.98596 23.2751C5.5487 20.2257 6.26354 17.199 7.09245 14.2104C7.82249 11.5792 8.62098 8.95556 9.6324 6.41561C10.1875 5.02396 11.0164 2.24065 12.9937 2.75777C13.6705 2.93268 14.0355 3.16082 14.4461 3.85284C15.3359 5.33575 14.6895 7.29775 14.0811 8.75024C13.526 10.081 15.9975 10.1495 16.4462 9.08484C17.5184 6.52207 18.0964 2.8034 15.1001 1.31289C12.5298 0.0353056 9.71605 1.29768 8.37003 3.68554C7.11526 5.89849 6.3548 8.44605 5.60954 10.8643C4.5601 14.2484 3.67036 17.6857 2.94031 21.1458C2.30152 24.1877 1.6171 27.3968 1.73877 30.53C1.79201 31.8684 4.28633 31.5338 4.2407 30.3703H4.2331Z"
          fill="#F06E36"
        />
        <path
          d="M4.06495 25.9143C5.7912 22.1728 7.96614 18.5606 10.4453 15.2602C12.2628 12.8419 14.3464 10.3856 16.9624 8.81144C18.0195 8.18026 19.1526 7.62512 20.2629 8.44642C21.1298 9.08521 21.5709 10.2639 21.434 11.321C21.3427 11.9902 21.0233 12.416 20.5975 12.918C19.9967 13.6252 19.4187 14.3476 18.8332 15.0777C18.0651 16.0359 17.3046 17.0017 16.5518 17.9674C16.202 18.4161 16.856 18.553 17.183 18.553C17.7001 18.553 18.4834 18.3553 18.818 17.9142C19.5708 16.9484 20.3313 15.9826 21.0994 15.0244C21.6545 14.3248 22.2173 13.6328 22.7952 12.9484C23.2363 12.4237 23.6545 11.9294 23.8218 11.2525C24.1184 10.0662 23.7686 8.743 22.9321 7.85326C21.8522 6.71256 20.164 6.6137 18.7191 6.91028C15.335 7.60991 12.4833 10.2411 10.2627 12.7354C8.04218 15.2298 6.08779 18.15 4.36914 21.0854C3.38814 22.766 2.43755 24.4846 1.62386 26.2489C1.40332 26.728 2.46037 26.6748 2.65048 26.652C3.12958 26.5911 3.84441 26.3706 4.07255 25.8839L4.06495 25.9143Z"
          fill="#F06E36"
        />
        <path
          d="M10.4221 23.2303C11.7377 21.2455 13.9507 19.4508 16.3005 18.9489C18.3081 18.5154 20.9926 18.9108 22.658 20.1656C23.9508 21.139 24.331 22.6447 23.9964 24.1885C23.8215 24.9794 23.4185 26.0896 22.5667 26.3862C22.1561 26.5307 21.7987 26.3026 21.4032 26.1809C19.8519 25.6942 18.1484 25.6714 16.5743 26.0592C14.6731 26.5307 13.0229 27.6562 12.1256 29.4281C11.2282 31.2 11.0685 33.2076 12.2472 34.8046C14.5058 37.8692 19.4488 37.5803 22.1333 35.3217C22.9394 34.6449 23.6238 33.7779 23.9736 32.7665C24.3767 31.6106 24.1181 30.6524 23.966 29.4965C23.715 27.5725 25.3348 25.3216 27.3653 26.295C30.5973 27.8387 29.7607 32.8654 28.8634 35.6335C27.814 38.8731 25.8063 42.1811 22.6352 43.6944C18.5211 45.664 13.0229 44.3788 9.58561 41.6183C6.1483 38.8578 4.78707 34.7589 4.26235 30.6524C4.21672 30.3178 4.1787 29.9756 4.1711 29.6334C4.15589 29.0022 1.68437 29.4433 1.70719 30.295C1.74521 31.7475 2.10263 33.2304 2.49807 34.6221C3.15207 36.9111 4.02661 39.1164 5.60837 40.9263C8.90119 44.6982 14.0039 46.1659 18.8861 45.702C23.3957 45.2686 27.274 42.9491 29.601 39.048C31.2893 36.2114 32.3235 32.8426 32.0269 29.5193C31.8292 27.2912 30.6353 25.3292 28.3159 24.8653C26.1485 24.4318 23.5249 25.4128 22.2474 27.2379C21.6238 28.1277 21.3424 29.2075 21.5249 30.2874C21.7454 31.6182 21.8823 32.7437 21.1371 33.9604C20.0268 35.7551 18.3386 36.2114 16.4678 35.3977C14.5971 34.584 13.5933 32.8426 13.9659 30.8654C14.3385 28.8881 15.5324 27.063 17.6237 27.025C19.1218 26.9946 20.407 27.8235 21.9127 27.6258C24.0268 27.352 25.9432 26.0668 26.4451 23.9147C26.8101 22.3405 26.5592 20.6979 25.3348 19.5572C23.6694 17.9983 20.9241 17.5496 18.734 17.5648C14.81 17.5952 11.0761 19.6333 8.63503 22.6523C8.46772 22.8577 8.30803 23.063 8.16354 23.2759C7.6084 24.12 10.0343 23.8235 10.4297 23.2303H10.4221Z"
          fill="#F06E36"
        />
        <path
          d="M29.5178 15.2032C30.3771 13.3705 31.1148 11.4769 32.0654 9.68983C32.3315 9.18792 31.8296 8.86853 31.3657 8.85332C30.8334 8.8305 30.0273 9.02823 29.746 9.55295C28.7954 11.34 28.0653 13.2412 27.1984 15.0663C26.955 15.5758 27.4113 15.8876 27.898 15.9028C28.4075 15.918 29.2669 15.7279 29.5178 15.2032Z"
          fill="#F06E36"
        />
        <path
          d="M33.1531 18.8829C33.29 18.7612 33.4269 18.6471 33.5714 18.5254C33.6322 18.4722 33.693 18.419 33.7539 18.3733C33.5638 18.533 33.7995 18.3353 33.8223 18.3125C34.0581 18.1148 34.3014 17.9247 34.5447 17.7346C34.9782 17.3923 35.4269 17.0577 35.8756 16.7383C36.6056 16.2136 37.3585 15.7193 38.1037 15.2098C38.4687 14.9589 38.7957 14.3657 38.2786 14.0843C37.7615 13.8029 37.0011 13.9474 36.522 14.2744C35.7615 14.7916 34.9858 15.3011 34.233 15.841C33.1607 16.6091 32.1341 17.4456 31.1303 18.3125C30.9706 18.4494 30.8565 18.7232 30.9097 18.9285C30.9706 19.149 31.2063 19.3011 31.4116 19.3543C32.0352 19.514 32.6816 19.3011 33.1531 18.8905V18.8829Z"
          fill="#F06E36"
        />
        <path
          d="M33.7314 23.5983C35.7086 23.682 37.6782 23.7048 39.6478 23.5603C40.1726 23.5223 40.971 23.1573 40.9939 22.5337C41.0167 21.9101 40.3855 21.6363 39.8456 21.6744C38.933 21.7428 38.0204 21.7884 37.1079 21.7884C36.6744 21.7884 36.2409 21.7884 35.8075 21.7732C35.7086 21.7732 35.6098 21.7732 35.5109 21.7656C35.374 21.7656 35.374 21.7656 35.5261 21.7656C35.4577 21.7656 35.3892 21.7656 35.3208 21.7656C35.0698 21.758 34.8189 21.7428 34.5679 21.7352C33.99 21.7124 33.1991 21.8873 32.9709 22.5033C32.7656 23.0356 33.1611 23.5679 33.739 23.5907L33.7314 23.5983Z"
          fill="#F06E36"
        />
      </g>
      <defs>
        <clipPath id="clip0_2687_1021">
          <rect
            width="40.875"
            height="44.9815"
            fill="white"
            transform="translate(0.125 0.798828)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FooterHeadindHand;
