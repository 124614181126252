import React from "react";

function Facebook() {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2687_1059)">
        <path
          d="M24.7227 22L25.167 19.1044H22.3886V17.2253C22.3886 16.4331 22.7767 15.6609 24.0211 15.6609H25.2842V13.1956C25.2842 13.1956 24.138 13 23.042 13C20.7539 13 19.2583 14.3869 19.2583 16.8975V19.1044H16.7148V22H19.2583V29H22.3886V22H24.7227Z"
          fill="#007044"
        />
      </g>
      <circle opacity="0.25" cx="21" cy="21" r="20.5" stroke="#007044" />
      <defs>
        <clipPath id="clip0_2687_1059">
          <rect
            width="10"
            height="16"
            fill="white"
            transform="translate(16 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Facebook;
