import Link from "next/link";
import { Burger, Cross, Logo, LogoHorizontal } from "../../assets";
import { useState } from "react";
import { useRouter } from "next/router";

const Flags = ({ setActive }) => {
	const router = useRouter();

	return (
		<div className="navbar-flag">
			<Link
				href="/"
				prefetch={false}
				className={router.asPath === "/" ? "" : "other-flag"}
				onClick={() => setActive(false)}
			>
				<p>ES</p>
			</Link>
			<p>|</p>
			<Link
				href="/ct"
				prefetch={false}
				className={router.asPath === "/ct" ? "" : "other-flag"}
				onClick={() => setActive(false)}
			>
				<p>CA</p>
			</Link>
		</div>
	);
};

const Navbar = ({ links, name }) => {
	const [active, setActive] = useState(false);
	const router = useRouter();

	const handleClick = () => {
		setActive(!active);
	};

	return (
		<nav
			style={
				router.asPath === "/checkout"
					? { position: "inherit" }
					: { position: "sticky" }
			}
			className="navbar"
		>
			<div className="navbar-left">
				<Link href="/" className="navbar-logo">
					<div className="logo_mobile">
						<LogoHorizontal />
					</div>
					<div className="logo_desktop">
						<Logo />
					</div>
				</Link>
			</div>
			<div className="menu-icon" onClick={handleClick}>
				{!active ? <Burger /> : null}
			</div>
			<ul
				className={`nav-menu${active ? " active" : ""}${
					name === "with-catalan-flag" ? " with-flags" : ""
				}`}
			>
				<div onClick={handleClick} className="burger_menu_icon-active">
					{<Cross />}
				</div>
				{name === "with-catalan-flag" ? <Flags setActive={setActive} /> : null}
				{/*TODO: Found a way to make Link tag work with external link*/}
				<div className="menu-links">
					{links.map((l, i) => (
						<a key={i} onClick={() => setActive(false)} href={l.href}>
							{l.label}
						</a>
					))}
				</div>
			</ul>
		</nav>
	);
};

export default Navbar;
