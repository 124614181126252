import React from "react";

function Linkedin() {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1337 26.9997H14.2313V17.6528H17.1337V26.9997ZM15.6809 16.3778C14.7528 16.3778 14 15.6091 14 14.6809C14 14.2351 14.1771 13.8076 14.4923 13.4923C14.8076 13.1771 15.2351 13 15.6809 13C16.1267 13 16.5543 13.1771 16.8695 13.4923C17.1848 13.8076 17.3619 14.2351 17.3619 14.6809C17.3619 15.6091 16.6088 16.3778 15.6809 16.3778ZM27.9969 26.9997H25.1006V22.4497C25.1006 21.3653 25.0787 19.9747 23.5916 19.9747C22.0825 19.9747 21.8513 21.1528 21.8513 22.3716V26.9997H18.9519V17.6528H21.7356V18.9278H21.7762C22.1637 18.1934 23.1103 17.4184 24.5225 17.4184C27.46 17.4184 28 19.3528 28 21.8653V26.9997H27.9969Z"
        fill="#007044"
      />
      <circle opacity="0.25" cx="21" cy="21" r="20.5" stroke="#007044" />
    </svg>
  );
}

export default Linkedin;
