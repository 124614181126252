import React from "react";

function Logo() {
  return (
    <svg
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2324_709)">
        <path
          d="M32.8482 67.0008C32.5771 60.6031 34.437 54.3594 43.382 53.985C51.8682 53.6273 54.8207 58.2737 55.1209 65.366L55.4128 96.0482C55.6839 102.446 54.1618 109.605 45.1459 109.983C36.7223 110.337 33.4362 104.775 33.1359 97.683L32.844 67.0008H32.8482ZM39.7373 97.787C39.9041 101.714 41.568 103.923 45.5046 104.068C48.2694 104.168 49.0492 101.331 48.8824 97.4043L48.5529 65.8361C48.3611 61.3394 47.3603 59.6256 44.3328 59.7545C41.372 59.8793 39.216 61.7263 39.4078 66.2229L39.7373 97.7912V97.787Z"
          fill="#F06E36"
        />
        <path
          d="M84.5538 63.1198C84.5288 57.3211 88.4987 51.406 98.5029 51.9301C107.994 52.4251 109.329 57.5582 109.52 63.802L110 98.3902C109.7 104.093 106.877 110.295 96.8015 109.767C87.3853 109.276 84.6997 104.027 85.0292 97.708L84.5496 63.1198H84.5538ZM92.3728 98.4318C92.3144 102.891 94.8248 103.698 97.8399 103.856C100.93 104.018 102.415 102.467 102.598 98.9642L102.16 63.5857C102.06 59.3261 101.193 57.9617 97.8107 57.787C94.8332 57.6331 92.7106 59.2887 92.0892 62.0799C91.9683 62.6165 91.9391 63.1697 91.9475 63.7188L92.377 98.4318H92.3728Z"
          fill="#F06E36"
        />
        <path
          d="M80.955 97.3794L79.6247 57.4792C79.2452 52.0549 76.1051 50.4326 72.6522 50.6697C71.2552 50.7654 70.025 51.1439 68.97 51.8844C67.9066 52.5458 67.0892 53.4859 66.5012 54.6339L66.3553 54.6423C66.3553 54.6423 66.9892 52.5915 66.1384 51.5641C65.2919 50.5324 59.9041 49.8419 59.6706 52.0133C59.437 54.1805 59.8665 97.5582 61.2218 98.4609C62.5771 99.3636 66.8849 98.8852 68.1276 97.9825C69.3745 97.0799 67.1851 60.2579 67.1851 60.2579C66.985 57.4002 68.236 56.2812 69.9291 56.1647C72.06 56.0191 72.6731 57.3752 72.8023 59.2804L74.0575 96.6473C74.1576 99.297 74.8665 99.0849 77.4312 99.4509C81.0592 99.9667 81.0342 98.7937 80.9466 97.3794"
          fill="#F06E36"
        />
        <path
          d="M60.5088 109.7C59.5705 109.676 59.0617 106.086 60.5088 105.116C62.7481 103.615 80.146 102.933 81.7473 104.963C83.1109 106.693 82.2227 109.268 81.1801 109.476C80.4879 109.613 61.2636 109.721 60.5004 109.7"
          fill="#F06E36"
        />
        <path
          d="M0.533785 60.3369C1.42203 59.1389 11.4095 58.7646 17.3186 59.5965C22.5355 60.3286 26.6722 61.589 27.1226 71.9218C27.6272 83.4027 22.7857 84.6131 14.2911 85.2745C24.3995 84.9584 30.5338 88.7188 30.9341 96.0067C31.4304 105.004 28.7156 109.239 17.6147 109.58C17.6147 109.58 3.20268 110.487 1.50126 108.573C-0.20016 106.66 -0.354455 61.5391 0.533785 60.3369ZM7.20184 79.9875C7.3061 80.7945 15.3962 81.4185 18.3528 78.6023C19.8499 77.1797 21.0467 72.5832 19.3453 68.386C17.2352 63.178 7.77732 64.921 7.35197 66.198C6.397 69.0557 6.90159 77.6373 7.20184 79.9917M8.17766 102.708C9.72478 104.018 13.5321 103.864 18.6155 102.879C26.2928 101.385 24.4704 92.7995 18.04 91.4559C9.83737 89.7463 7.31444 90.8278 7.26439 92.5957C7.22269 94.0266 7.27273 101.947 8.17766 102.708Z"
          fill="#F06E36"
        />
        <path
          d="M82.794 39.1015L82.8398 8.96841C82.6939 3.41101 79.5579 1.60985 76.0175 1.70136C74.5871 1.7388 73.3152 2.07158 72.2018 2.77873C71.0884 3.41101 70.2085 4.33447 69.558 5.4784H69.4078C69.4078 5.4784 70.1459 3.41933 69.3244 2.32948C68.5029 1.23963 63.0317 0.29537 62.6981 2.50003C62.3645 4.70469 61.2385 41.9177 62.5855 42.8994C63.9283 43.8811 68.3486 43.5816 69.6622 42.7163C70.9758 41.847 70.0083 11.2563 70.0083 11.2563C69.9333 8.32782 71.2594 7.23796 72.9941 7.19637C75.1793 7.13813 75.7423 8.55244 75.7923 10.5075L75.7798 38.0491C75.7381 40.6739 76.5012 40.5782 79.0992 41.0649C82.7856 41.7554 82.8107 40.5533 82.7856 39.1057"
          fill="#F06E36"
        />
        <path
          d="M34.4245 12.0341C34.2119 5.39103 37.9275 1.36441 44.7415 1.1481C50.6172 0.960916 55.0793 2.10484 55.784 10.5741C56.1927 15.4493 57.0184 20.1331 53.9658 22.3752C51.568 24.1348 45.6589 23.9351 43.2319 23.7687C42.6606 23.7271 42.181 24.1889 42.2019 24.7588L42.4354 34.0558C42.5522 37.7288 43.8366 38.7063 46.1885 38.6315C48.9283 38.5441 49.6497 36.5641 49.5538 33.4401C49.5538 33.4401 49.312 30.4202 50.2502 29.4801C51.1885 28.5441 54.4287 27.5707 55.6339 28.2737C56.8391 28.9767 57.0184 30.129 56.4429 35.2413C55.8674 40.3578 53.9366 43.0782 45.7882 43.3361C40.2252 43.5108 35.2878 41.1647 35.0584 33.8977L34.4245 12.0341ZM42.7398 18.4734C44.0117 19.5133 48.4237 19.1847 49.1618 18.02C49.8999 16.8553 49.4037 11.8636 49.4037 11.8636C49.0951 7.93679 47.7565 7.07988 45.2502 7.15892C42.5105 7.24627 42.0517 9.41765 41.7848 11.5724C41.7848 11.5724 41.4721 17.4376 42.744 18.4776"
          fill="#F06E36"
        />
        <path
          d="M0.621342 1.21466C1.53043 -0.0124569 11.7431 -0.390993 17.7856 0.457593C23.1193 1.20635 27.3478 2.49586 27.8107 13.0616C28.3236 24.8004 23.3778 26.04 14.6914 26.718C25.0292 26.3977 31.3011 30.2371 31.7139 37.6955C32.2227 46.8969 29.4454 51.2271 18.0901 51.5766C18.0901 51.5766 3.35278 52.5042 1.60967 50.5449C-0.129284 48.5857 -0.287749 2.44179 0.621342 1.21466ZM7.44369 21.3103C7.54795 22.134 15.8215 22.7746 18.849 19.8919C20.3795 18.436 21.6055 13.7355 19.8624 9.44262C17.7022 4.11816 8.03168 5.89852 7.59799 7.20468C6.62218 10.129 7.1351 18.9019 7.44369 21.3103ZM8.44453 44.5424C10.025 45.8819 13.9199 45.728 19.1159 44.7172C26.9683 43.1905 25.1042 34.4093 18.5279 33.0366C10.1376 31.2854 7.56046 32.3919 7.51042 34.2014C7.46871 35.6656 7.51876 43.7604 8.44453 44.5424Z"
          fill="#F06E36"
        />
        <path
          d="M87.598 12.0341C87.3853 5.39103 91.1009 1.36441 97.9149 1.1481C103.791 0.960916 108.253 2.10484 108.957 10.5741C109.362 15.4493 110.192 20.1331 107.139 22.3752C104.741 24.1348 98.8324 23.9351 96.4053 23.7687C95.834 23.7271 95.3545 24.1889 95.3753 24.7588L95.6089 34.0558C95.7256 37.7288 97.01 38.7063 99.362 38.6315C102.102 38.5441 102.823 36.5641 102.727 33.4401C102.727 33.4401 102.485 30.4202 103.424 29.4801C104.362 28.5441 107.602 27.5707 108.807 28.2737C110.013 28.9726 110.192 30.129 109.616 35.2413C109.041 40.3578 107.11 43.0782 98.9616 43.3361C93.3987 43.5108 88.4654 41.1647 88.2319 33.8977L87.598 12.0341ZM95.9133 18.4734C97.1852 19.5133 101.597 19.1847 102.335 18.02C103.073 16.8553 102.577 11.8636 102.577 11.8636C102.269 7.93679 100.93 7.07988 98.4237 7.15892C95.6797 7.24627 95.2252 9.41765 94.9583 11.5724C94.9583 11.5724 94.6455 17.4376 95.9174 18.4776"
          fill="#F06E36"
        />
      </g>
      <defs>
        <clipPath id="clip0_2324_709">
          <rect width="110" height="110" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
